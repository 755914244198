import React from 'react';
import { BsPen, BsPerson, BsSend, BsPhone, BsTextarea, BsTelephone, BsGeoAlt, BsWhatsapp } from "react-icons/bs";
import { AiFillInstagram, } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa6"
import Banner from '../../components/Bannersection/banner';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ContactUS() {
    const sendEmail = (event) => {
        event.preventDefault();
      
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_US_SERVICE_ID, process.env.REACT_APP_EMAILJS_US_TEMPLATE_ID, event.target, process.env.REACT_APP_EMAILJS_US_USER_ID)
          .then((result) => {
            console.log(result.text);
            toast.success('Message sent successfully!', {
              position: toast.POSITION.TOP_RIGHT
            });
          }, (error) => {
            console.log(error.text);
            toast.error('Error sending message. Please try again later.', {
              position: toast.POSITION.TOP_RIGHT
            });
          });
      
        // Clear the form after sending the email
        event.target.reset();
      };
    const pagename = [
        {
            pagetitle: "Contact Us",
            subtitle: "Contact",
        }
    ]
    return (
        <div>
            {/* <!-- breadcrumb-area-start --> */}
            <Banner items={pagename} />

            {/* <!-- breadcrumb-area-end --> */}



            {/* <!-- contact-area-start --> */}
            <section className="contact-area py-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="tp-contact-main mb-40">
                                <div className="tp-section tp-section-two mb-25">
                                    <span className="tp-section-sub-title"><i ><BsTextarea className="flaticon-edit" /></i> Let’s Talk With Us</span>
                                    <h4 className="tp-section-title">Grow Your Business With Our Expertise</h4>
                                    <div className="tp-section-title-wrapper">
                                        <p>Get your project estimations for well-informed business decisions and top-notch digital outcomes.</p>
                                    </div>
                                </div>
                                <div className="tp-contact-main-thumb mb-40 w-img">
                                    <img src="assets/img/thumbs/contact-main-bg-1.jpg" alt="" />
                                </div>
                                <div className="tp-contact-location-wrap d-flex align-items-center">
                                    <div className="tp-contact-location">
                                        <span className="tp-contact-location-title">United States (USA)</span>
                                        <p><i><BsGeoAlt className="fa-light fa-location-dot" /></i>Sills Lane,<br /> Downingtown, <br />Pennsylvania-19335,<br /> United States.</p>
                                        {/* <a href="tel:+9104442031666"><i><BsTelephone className="fa-light fa-phone" /></i> +91 044 4203 1666</a>
                              <a href="mailto:support@dgiworx.in"><i><BsSend className="fa-light fa-envelope" /></i>support@dgiworx.in</a> */}
                                    </div>
                                    <div className="tp-contact-location ml-40">
                                        {/* <span className="tp-contact-location-title">USA</span>
                              <a href="#"><i><BsGeoAlt className="fa-light fa-location-dot" /></i>No.5/11, 1st Floor,
                                 <br />22nd Cross St,Indira Nagar,<br /> Adyar, Chennai,<br /> TamilNadu, India.</a> */}
                                        <a href="tel:+18383838771"><i><BsTelephone className="fa-light fa-phone" /></i> +1 838 383 8771</a>
                                        <a href="https://wa.me/+18383838771" target="_blank"><i><BsWhatsapp className="fa-light fa-phone" /></i>+1 838 383 8771</a>
                                        <a href="mailto:projects@dgiworx.com"><i><BsSend className="fa-light fa-envelope" /></i>projects@dgiworx.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="offset-xl-1 col-xl-6 col-lg-6">
                            <div className="tp-contact-details-form mb-40">
                            <form id="contactform" className="row" action="" name="contactform" method="post" onSubmit={sendEmail}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="tp-contact-details-form-input mb-20">
                                                <input type="text" name="first_name" id="fname" placeholder="First Name" required/>
                                                <span><i><BsPerson className="fa-light fa-user" /></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="tp-contact-details-form-input mb-20">
                                                <input type="text" placeholder="Last Name" name="last_name" id="lname" />
                                                <span><i><BsPerson className="fa-light fa-user" /></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="tp-contact-details-form-input mb-20">
                                                <input name="email" id="email" type="email" placeholder="Email Address" required/>
                                                <span><i><BsSend className="fa-light fa-envelope" /></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="tp-contact-details-form-input mb-20">
                                                <input type="text" placeholder="Phone Number" name="phone" id="phonenumber" required />
                                                <span><i><BsPhone className="fa-light fa-phone" /></i></span>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-12">
                                 <div className="tp-contact-details-form-input mb-20">
                                    <input type="text" placeholder="Company Name" />
                                    <span><i ><BsBuilding className="fa-light fa-user" /></i></span>
                                 </div>
                              </div>
                              <div className="col-lg-12">
                                 <div className="tp-contact-details-form-input mb-20">
                                    <input type="text" placeholder="Your Subject" />
                                    <span><i><BsInfoCircle className="fa-sharp fa-light fa-circle-info" /></i></span>
                                 </div>
                              </div> */}
                                        <div className="col-lg-12">
                                            <div className="tp-contact-details-form-input mb-20">
                                                <textarea name="comments" id="message" placeholder="Write Your Message" required></textarea>
                                                <span><i><BsPen className="fa-light fa-pen" /></i></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="tp-contact-details-form-btn">
                                                <button className="tp-btn">Send a Message</button>
                                            </div>
                                        </div>
                                        <ToastContainer />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- contact-area-end --> */}


            {/* <!-- team-area-start --> */}
            <section className="team-area py-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tp-team-3-wrapper text-center">
                                <div className="tp-section tp-section-3 mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                                    <span className="tp-section-sub-title">Meet With Our Specialist</span>
                                    <h2 className="tp-section-title">Our Core Team Members</h2>
                                    <p>When you want an exclusive website developed by the professionals, think of us, that is DGi WORX Team.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="tp-team-3-item mb-15">
                                <div className="tp-team-3-thumb">
                                    <div className="tp-team-3-thumb-wrap tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/team/08.jpg" alt="" loading="lazy" />
                                    </div>
                                    {/* <div className="tp-team-3-thumb-social">
                                        <a href="#"><i><FaLinkedinIn className="fa-brands fa-linkedin-in" /></i></a>
                                        <a href="#"><i><FaFacebookF className="fa-brands fa-facebook-f" /></i></a>
                                        <a href="#"><i> <AiFillInstagram className="fa-brands fa-instagram" /></i></a>
                                        <a href="#"><i> <BsWhatsapp className="fa-brands fa-Whatsapp" /></i></a>
                                    </div> */}
                                </div>
                                <div className="tp-team-3-content">
                                    <h5 className="tp-team-3-title">Prabha</h5>
                                    <span>Managing Director</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="tp-team-3-item mb-15">
                                <div className="tp-team-3-thumb">
                                    <div className="tp-team-3-thumb-wrap tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/team/09.jpg" alt="" loading="lazy" />
                                    </div>
                                    {/* <div className="tp-team-3-thumb-social">
                                        <a href="#"><i><FaLinkedinIn className="fa-brands fa-linkedin-in" /></i></a>
                                        <a href="#"><i><FaFacebookF className="fa-brands fa-facebook-f" /></i></a>
                                        <a href="#"><i> <AiFillInstagram className="fa-brands fa-instagram" /></i></a>
                                        <a href="#"><i> <BsWhatsapp className="fa-brands fa-Whatsapp" /></i></a>
                                    </div> */}
                                </div>
                                <div className="tp-team-3-content">
                                    <h5 className="tp-team-3-title">Prabhu</h5>
                                    <span>VP - Technology</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="tp-team-3-item mb-15">
                                <div className="tp-team-3-thumb">
                                    <div className="tp-team-3-thumb-wrap tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/team/02.jpg" alt="" loading="lazy" />
                                    </div>
                                    {/* <div className="tp-team-3-thumb-social">
                                        <a href="#"><i><FaLinkedinIn className="fa-brands fa-linkedin-in" /></i></a>
                                        <a href="#"><i><FaFacebookF className="fa-brands fa-facebook-f" /></i></a>
                                        <a href="#"><i> <AiFillInstagram className="fa-brands fa-instagram" /></i></a>
                                        <a href="#"><i> <BsWhatsapp className="fa-brands fa-Whatsapp" /></i></a>
                                    </div> */}
                                </div>
                                <div className="tp-team-3-content">
                                    <h5 className="tp-team-3-title">Rajesh</h5>
                                    <span>Strategic Business Partner</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="tp-team-3-item mb-15">
                                <div className="tp-team-3-thumb">
                                    <div className="tp-team-3-thumb-wrap tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/team/03.jpg" alt="" loading="lazy" />
                                    </div>
                                    {/* <div className="tp-team-3-thumb-social">
                                        <a href="#"><i><FaLinkedinIn className="fa-brands fa-linkedin-in" /></i></a>
                                        <a href="#"><i><FaFacebookF className="fa-brands fa-facebook-f" /></i></a>
                                        <a href="#"><i> <AiFillInstagram className="fa-brands fa-instagram" /></i></a>
                                        <a href="#"><i> <BsWhatsapp className="fa-brands fa-Whatsapp" /></i></a>
                                    </div> */}
                                </div>
                                <div className="tp-team-3-content">
                                    <h5 className="tp-team-3-title">Reuben Raj</h5>
                                    <span>Head Of Programmer</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="tp-team-3-item mb-15">
                                <div className="tp-team-3-thumb">
                                    <div className="tp-team-3-thumb-wrap tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/team/05.jpg" alt="" loading="lazy" />
                                    </div>
                                    {/* <div className="tp-team-3-thumb-social">
                                        <a href="#"><i><FaLinkedinIn className="fa-brands fa-linkedin-in" /></i></a>
                                        <a href="#"><i><FaFacebookF className="fa-brands fa-facebook-f" /></i></a>
                                        <a href="#"><i> <AiFillInstagram className="fa-brands fa-instagram" /></i></a>
                                        <a href="#"><i> <BsWhatsapp className="fa-brands fa-Whatsapp" /></i></a>
                                    </div> */}
                                </div>
                                <div className="tp-team-3-content">
                                    <h5 className="tp-team-3-title">S.A.K.Pandian</h5>
                                    <span>Web Artist</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="tp-team-3-item mb-15">
                                <div className="tp-team-3-thumb">
                                    <div className="tp-team-3-thumb-wrap tp-thumb-common fix">
                                        <div className="tp-thumb-common-overlay wow"></div>
                                        <img src="assets/img/team/06.jpg" alt="" loading="lazy" />
                                    </div>
                                    {/* <div className="tp-team-3-thumb-social">
                                        <a href="#"><i><FaLinkedinIn className="fa-brands fa-linkedin-in" /></i></a>
                                        <a href="#"><i><FaFacebookF className="fa-brands fa-facebook-f" /></i></a>
                                        <a href="#"><i> <AiFillInstagram className="fa-brands fa-instagram" /></i></a>
                                        <a href="#"><i> <BsWhatsapp className="fa-brands fa-Whatsapp" /></i></a>
                                    </div> */}
                                </div>
                                <div className="tp-team-3-content">
                                    <h5 className="tp-team-3-title">Hari Hara Suthan</h5>
                                    <span>Software Craftsman</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- team-area-end --> */}

            {/* <div className="map-area">
                <div className="tp-map-wrapper">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d46263392.90799347!2d-6.8004560186699345!3d44.94990011898704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1686374976614!5m2!1sen!2sbd"></iframe>
                </div>
            </div> */}

            {/* <!-- map-area-end --> */}
        </div>
    )
}

export default ContactUS;