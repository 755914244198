// Preloader.js
import React from 'react';
import './preloader.css';
import ScaleLoader from "react-spinners/ScaleLoader";

function Pagepreloader({ isLoading }) {
  return (
    <div className={`loading-screen ${isLoading ? 'visible' : 'hidden'}`}>
      <div className='logos2'>
      <ScaleLoader
  color="#ED1C24"
  width={5}
/>
      </div>
    </div>
  );
}

export default Pagepreloader;
