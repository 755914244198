import React from 'react';
import Slider2 from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Subcard = ({ item }) => {

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        centerPadding: "60px",
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3, // Number of slides to show at a time
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1285, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className='' >

            <Slider2 {...settings}>
                {item.map((item, index) => (
                    <div className="">
                        <div className="col-md-11" key={index}>
                            <div className="tp-project-3-item mb-30 tp-thumb-common fix wow fadeInRight" data-wow-duration="1.2s"
                                data-wow-delay=".6s">
                                <div className="tp-project-3-thumb">
                                    <div className="tp-thumb-common-overlay wow"></div>
                                    <img src={item.image} alt={item.title} loading='lazy' />
                                </div>
                                
                                <div className="dgi-project-3-info d-flex justify-content-center align-items-center">
                                    
                                    <div className="tp-project-3-content">
                                        <span>{item.category}</span>
                                        <h4 className="tp-project-3-title under-line-white"> {item.title}</h4>
                                    </div>
                                    {/* <div className="tp-project-3-icon">
                          <a href={project.url} target='_blank'><i><AiOutlineArrowRight className="fa-regular fa-arrow-right" /></i></a>
                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider2>

        </div >
    )
}
export default Subcard;