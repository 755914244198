import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaStar } from 'react-icons/fa';
import  studentreview from './studentreview.json'

function Studentreview() {
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 2, // Number of slides to show at a time
        slidesToScroll: 2,
        responsive: [

            {
               breakpoint:900, // Adjust this breakpoint as needed
               settings: {
                   slidesToShow: 1,
                   slidesToScroll: 1,
               },
            },
        ],
    };
  return (
    <div>
        <Slider {...settings}>
            {studentreview.map((studentreview, id) => (
             <div className="col-lg-6 student-review-card" key={id}>
             <div className="tp-testimonial-5-item mb-10 ">
                <div className="tp-testimonial-5-content">
                   <p>{studentreview.description}</p>
                </div>
                <div className="tp-testimonial-5-avatar d-flex">
                   <div className="tp-testimonial-5-avatar-thumb">
                      <img src={studentreview.image} alt="" loading="lazy"/>
                   </div>
                   <div className="tp-testimonial-5-avatar-content">
                      <div className="tp-testimonial-5-avatar-rating mb-45">
                     <FaStar style={{ color: 'gold', marginRight: '5px' }} />
                     <FaStar style={{ color: 'gold', marginRight: '5px' }} />
                     <FaStar style={{ color: 'gold', marginRight: '5px' }} />
                     <FaStar style={{ color: 'gold', marginRight: '5px' }} />
                     <FaStar style={{ color: 'gold', marginRight: '5px' }} />
                      </div>
                      <h5 className="tp-testimonial-5-avatar-title">{studentreview.name}</h5>
                      <span>{studentreview.course}</span>
                      <div className="tp-testimonial-5-avatar-quote">
                         <img src="assets/img/testimonial/testimonial-5-quote.png" alt="" loading="lazy"/>
                      </div>
                   </div>
                </div>
                <div className="tp-testimonial-5-shape-1">
                   <img src="assets/img/testimonial/testimonial-5-shape-1.png" alt="" loading="lazy"/>
                </div>
                <div className="tp-testimonial-5-shape-2">
                   <img src="assets/img/testimonial/testimonial-inner-shape-dgi-1.jpg" alt="" loading="lazy"/>
                </div>
             </div>
          </div>

            ))}
        </Slider>
        
    </div>
  )
}

export default Studentreview;