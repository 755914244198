import React from 'react';

const Faq = ({ items }) => {
    return (
        <div>
            <section className='dgi-faq py-50'>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12">
                            <div className="tp-section text-center mb-50">
                                <span className="tp-section-sub-title">Answers to Common Questions</span>
                                <h2 className="tp-section-title">FAQ</h2>
                                <p> We've compiled a list of frequently asked questions to help you better understand our services and process.</p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="tp-faq tp-faq-inner-2-wrap mb-60 ml-70 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                            {/* <div className="tp-faq-content">
                                <h4 className="tp-faq-content-title">Questions & Answer</h4>
                            </div> */}
                            <div className="tp-faq-accordion">
                                <div className="accordion" id="accordionExample">
                                    {items.map((items, index) => (
                                        <div className="accordion-item" key={index}>
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={items.data_bs_target} aria-expanded="false" aria-controls={items.Faq_id}>
                                                    {items.question}
                                                    <span className="accordion-btn"></span>
                                                </button>
                                            </h2>
                                            <div id={items.Faq_id} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>{items.answer}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Faq;