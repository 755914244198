import React from 'react';
import {useEffect} from 'react';
import {BsFillRocketFill} from 'react-icons/bs'
import './scroll.css'


function Scrolltop() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  return (
    <div><button className='arrow'
    onClick={() => {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }}
  >
   <BsFillRocketFill/>
  </button></div>
  )
}

export default Scrolltop;

