import React from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BanneremailUs() {
  const sendEmail = (event) => {
    event.preventDefault();
  
    emailjs.sendForm(process.env.REACT_APP_EMAILJS_US_SERVICE_ID, process.env.REACT_APP_EMAILJS_US_EMAIL_TEMPLATE_ID, event.target, process.env.REACT_APP_EMAILJS_US_USER_ID)
      .then((result) => {
        console.log(result.text);
        toast.success('Message sent successfully!', {
            position: toast.POSITION.BOTTOM_CENTER
        });
      }, (error) => {
        console.log(error.text);
        toast.error('Error sending message. Please try again later.', {
            position: toast.POSITION.BOTTOM_CENTER
        });
      });
  
    // Clear the form after sending the email
    event.target.reset();
  };
  return (
    <div className="tp-banner-btn wow fadeInUp" data-wow-duration="1.4s" data-wow-delay=".9s">
    {/* <a className="tp-btn" href="about.html.htm">Discover More</a> */}
    <form id="contactform"  action="" name="contactform" method="post" onSubmit={sendEmail}>
        <div className='col-lg-4 emailinput'>
            <input type='text' name="email" id="name" placeholder='Email' className='emailinput' required></input>
            <button>submit</button>
        </div>
        <ToastContainer />
    </form>
</div>
  )
}

export default BanneremailUs;