import { useCallback } from "react";
import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim";
import './animction.css'

function Particlesanimation() {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);
  return (
    <div>
        <Particles
           className="w-full h-screen"
           id="tsparticles"
           init={particlesInit}
           loaded={particlesLoaded}
           options={{
               fullScreen:false,
               background: {
                   // color: {
                   //     value: "#0d47a1",
                   // },
               },
               fpsLimit: 60,
               interactivity: {
                   events: {
                       onClick: {
                           enable: true,
                           mode: "push",
                       },
                       onHover: {
                           enable: true,
                           // mode: "repulse",
                           mode:"grab",
                           distance:400,
                       },
                       resize: true,
                   },
                   modes: {
                       push: {
                           quantity: 4,
                       },
                       repulse: {
                           distance: 150,
                           duration: 0.4,
                       },
                       grab:{
                           distance:300,
                           link_linked:{
                               opacity: 0.5,
                           },
                       },
                   },
               },
               particles: {
                   color: {
                       value: "#ED1C24",
                   },
                   links: {
                       color: "#fcfcfc",
                       distance: 120,
                       enable: true,
                       opacity: 0.5,
                       width: 1,
                   },
                   move: {
                       direction: "none",
                       enable: true,
                       outModes: {
                           default: "bounce",
                       },
                       random: false,
                       speed: 2,
                       straight: false,
                   },
                   number: {
                       density: {
                           enable: true,
                           area: 1200,
                       },
                       value: 80,
                   },
                   opacity: {
                       value: 0.5,
                   },
                   shape: {
                       type: "circle",
                   },
                   size: {
                       value: { min: 1, max:4 },
                   },
               },
               detectRetina: true,
           }}
       />
    </div>
  )
}

export default Particlesanimation;