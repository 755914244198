import React from 'react';

const Boxcard = ({ item }) => {
    return (
        <div>
            <div className='row'>
                {item.map((item, index) => (
                    <div className="col-lg-4 col-md-6 dgiginitespeciality" key={index}>
                    <div className="tp-feature-item mb-30 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                        <div className="tp-feature-icon">
                            <i><item.icons className="flaticon-solution-1" /></i>
                        </div>
                        <div className="tp-feature-content">
                            <h4 className="tp-feature-content-title">{item.title}</h4>
                            <p>{item.description}</p>
                        </div>
                        <div className="tp-feature-shape-two">
                            <img src="assets/img/feature/one/feature-shape-1.png" alt="" loading="lazy" />
                        </div>
                    </div>
                </div>
                ))}
                
            </div>
        </div>
    )
}

export default Boxcard;