import React, { useState } from 'react';
import { TfiMenu } from "react-icons/tfi";
import { IoCall } from "react-icons/io5";
import {  BsFillCaretDownFill } from "react-icons/bs";
import { AiFillInstagram, } from "react-icons/ai";
import {  FaLinkedinIn } from "react-icons/fa6"
import { TfiYoutube } from "react-icons/tfi";
import './header.css';
import Countryselect from '../Countryselect/countryselect';
//import Animatedcursor from '../Animecursor/animatedcursor';








function HeaderUS() {

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isMobileSubMenuOpen, setMobileSubMenuOpen] = useState(false);


    const openMobileMenu = () => {
        setMobileMenuOpen(true);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };
    const toggleMobileSubMenu = () => {
        setMobileSubMenuOpen(!isMobileSubMenuOpen);
    };


    return (

        <>
            {/* <Animatedcursor/> */}
            <header className='header1' id="header1">
                <div className="tp-header-area tp-header-transparent tp-header-height theme-bg home-banner" id="header1">
                    <div className="container">
                        <div className="tp-header-box">
                            <div className="row align-items-center">
                                <div className="col-xl-2 col-lg-6 col-md-6 col-6">
                                    <div className="logo">
                                        <a href="/">
                                            <img data-width="115" src="assets/img/logo/dgilogo/dgi.png" alt="" loading='lazy' />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-9 d-none d-xl-block">
                                    <div className="main-menu">
                                        <nav id="mobile-menu" className="tp-main-menu-content">
                                            <ul>
                                                <li className=" tp-static">
                                                    <a href="/">Home</a>
                                                </li>
                                                <li className=" tp-static">
                                                    <a href="/about">About</a>
                                                </li>
                                                <li className="has-dropdown">
                                                    <a href="#">Our Services</a>

                                                    <ul className="tp-submenu">
                                                        <li><a href="/webdevelopment">Web Development</a></li>
                                                        <li><a href="/appdevelopment">App Development </a></li>
                                                        <li><a href="/cloudservices">Cloud Services</a></li>
                                                        <li><a href="/automation">Automation</a></li>
                                                        <li><a href="/migrationservices">Migration Services</a></li>
                                                        <li><a href="/dataanalytics">Data Analytics</a></li>
                                                        <li><a href="/ecommerce">Ecommerce</a></li>
                                                        <li><a href="/design">Design Works </a></li>
                                                        <li class=""><a href="/seo">SEO</a></li>
                                                    </ul>
                                                </li>
                                                <li className=" tp-static">
                                                    <a href="/projects">Our Projects</a>
                                                </li>
                                                <li className=" tp-static">
                                                    <a href="/socialmedia">Social Media</a>
                                                </li>
                                                <li className="tp-static fire-gif">
                                                    <span className=''><img src="assets/img/fire/fire-1.gif"></img></span>
                                                    <a href="/dgiginite" className='fire-dgi'>DGignite</a>
                                                </li>
                                                <li><a href="/contact">Contact</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-xl-1 col-lg-6 col-md-6 col-6">
                                    <div className="tp-header-right d-flex align-items-center justify-content-end">
                                        {/* <div className="tp-header-search">
                                            <span><img src="assets/img/flags/us.png" alt="" loading='lazy'></img></span>
                                            <span>US</span>
                                        </div> */}
                                        <div className="tp-header-search">
                                        <Countryselect />
                                        </div>

                                        <div className="offcanvas-btn d-xl-none ml-20">
                                            <button className="offcanvas-open-btn"><i><TfiMenu className="fa-solid fa-bars" onClick={openMobileMenu} /></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* nav bar 2 */}
            <header className='header2' id="header2">
                <div className="tp-header-area tp-header-3 tp-header-height" >
                    {/* <div className="tp-header-3-top theme-bg d-none d-lg-block">
                        <div className="tp-header-3-top-wrap d-flex align-items-center justify-content-between">
                            <div className="tp-header-3-top-left d-flex align-items-center">
                                <div className="tp-header-3-top-left-item">
                                    <span><i className="fa-solid fa-location-dot"></i></span>
                                    <a href="#">76 San Fransisco Street. New York</a>
                                </div>
                                <div className="tp-header-3-top-left-item">
                                    <span><i className="fa-solid fa-envelope"></i></span>
                                    <a href="mailto:"><span className="__cf_email__">[email&#160;protected]</span></a>
                                </div>
                            </div>
                            <div className="tp-header-3-top-right d-flex align-items-center">
                                <div className="tp-header-3-top-info">
                                    <a href="#">Help</a>
                                    <a href="#">Support</a>
                                    <a href="#">Contact</a>
                                </div>
                                <div className="tp-header-3-top-social">
                                    <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                    <a href="#"><i className="fa-brands fa-instagram"></i></a>
                                    <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                    <a href="#"><i className="fa-brands fa-twitter"></i></a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div id="header-sticky" className="tp-header-3-wrap white-bg d-flex justify-content-between">
                        <div className="tp-header-3-main d-flex">
                            <div className="logo" data-background="assets/img/shape/logo-shape-1.png">
                                <a href="/">
                                    <img data-width="115" src="assets/img/logo/dgilogo/dgilogo-2.png" alt="" />
                                </a>
                            </div>
                            <div className="main-menu tp-header-3-menu d-none d-xl-block">
                                <nav id="mobile-menu" className="tp-main-menu-content">
                                    <ul>
                                        <li className=" tp-static">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className=" tp-static">
                                            <a href="/about">About</a>
                                        </li>
                                        <li className="has-dropdown">
                                            <a href="#">Our Services</a>

                                            <ul className="tp-submenu">
                                                <li><a href="/webdevelopment">Web Development</a></li>
                                                <li><a href="/appdevelopment">App Development </a></li>
                                                <li><a href="/cloudservices">Cloud Services</a></li>
                                                <li><a href="/automation">Automation</a></li>
                                                <li><a href="/migrationservices">Migration Services</a></li>
                                                <li><a href="/dataanalytics">Data Analytics</a></li>
                                                <li><a href="/ecommerce">Ecommerce</a></li>
                                                <li><a href="/design">Design Works </a></li>
                                                <li class=""><a href="/seo">SEO</a></li>
                                            </ul>
                                        </li>
                                        <li className=" tp-static">
                                            <a href="/projects">Our Projects</a>
                                        </li>
                                        <li className=" tp-static">
                                            <a href="/socialmedia">Social Media</a>
                                        </li>
                                        <li className="tp-static fire-gif">
                                            <span className=''><img src="assets/img/fire/fire.gif"></img></span>
                                            <a href="/dgiginite" className='fire-dgi'>DGignite</a>
                                        </li>
                                        <li><a href="/contact">Contact</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="tp-header-right d-flex align-items-center">
                            {/* <div class="tp-header-search2 ">
                                <span><img src="assets/img/flags/us.png" alt="" loading='lazy'></img></span>
                                <span>US</span>
                            </div> */}
                            <div class="tp-header-search2">
                            <Countryselect />
                            </div>
                            <div className="d-none d-md-block">
                                <div className="tp-header-4-contact d-flex align-items-center">
                                    <div className="tp-header-4-contact-icon">
                                        <i><IoCall className="fa-solid fa-phone-flip" /></i>
                                    </div>
                                    <div className="tp-header-4-contact-content">
                                        <span>Call Anytime</span>
                                        <a href="tel:18383838771">+1 838 383 8771</a>
                                    </div>
                                </div>
                            </div>
                            <div className="tp-header-btn ml-30 d-none d-lg-block">
                                <a className="tp-btn" href="contact">Get Solution</a>
                            </div>
                            <div className="offcanvas-btn d-xl-none ml-30">
                                <button className="offcanvas-open-btn"><i><TfiMenu className="fa-solid fa-bars offcanvas-open-btn" onClick={openMobileMenu} /></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* <!-- offcanvas area start --> */}
            <div className={`offcanvas__area ${isMobileMenuOpen ? 'offcanvas-opened' : ''}`}>
                <div className="offcanvas__wrapper">
                    <div className="offcanvas__close">
                        <button className="offcanvas__close-btn offcanvas-close-btn" onClick={closeMobileMenu}>
                            <svg width="12" height="12" viewbox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 1L1 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M1 1L11 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="offcanvas__content">
                        <div className="offcanvas__top mb-70 d-flex justify-content-between align-items-center">
                            <div className="offcanvas__logo logo">
                                <a href="/">
                                    <img data-width="115" src="assets/img/logo/dgilogo/dgilogo-2.png" alt="" />
                                </a>
                            </div>
                            {/* <div className="tp-header-search">
                                <span><img src="assets/img/flags/us.png" alt="" loading='lazy'></img></span>
                                <span>US</span>
                            </div> */}
                            <div class="tp-header-search2">
                           <Countryselect />
                           </div>
                        </div>
                        <div className="tp-main-menu-mobile">
                            <nav id="mobile-menu" className="tp-main-menu-content">
                                <ul>
                                    <li className=" tp-static">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className=" tp-static">
                                        <a href="/about">About</a>
                                    </li>
                                    <li className="has-dropdown" >
                                        <a href="#" onClick={toggleMobileSubMenu} >Our Services
                                            <button class="dropdown-toggle-btn" id="submenu"><i ><BsFillCaretDownFill onClick={toggleMobileSubMenu} /></i></button>
                                        </a>

                                        <ul className={`tp-submenu ${isMobileSubMenuOpen ? 'mobilesubmenu-opened' : ' '}`}>
                                            <li><a href="/webdevelopment">Web Development</a></li>
                                            <li><a href="/appdevelopment">App Development </a></li>
                                            <li><a href="/cloudservices">Cloud Services</a></li>
                                            <li><a href="/automation">Automation</a></li>
                                            <li><a href="/migrationservices">Migration Services</a></li>
                                            <li><a href="/dataanalytics">Data Analytics</a></li>
                                            <li><a href="/ecommerce">Ecommerce</a></li>
                                            <li><a href="/design">Design Works </a></li>
                                            <li class=""><a href="/seo">SEO</a></li>
                                        </ul>
                                    </li>
                                    <li className=" tp-static">
                                        <a href="/projects">Our Projects</a>
                                    </li>
                                    <li className=" tp-static">
                                        <a href="/socialmedia">Social Media</a>
                                    </li>
                                    <li className="tp-static fire-gif-mobile ">

                                        <img src="assets/img/fire/fire.gif" className='gif-min'></img>
                                        <a href="/dgiginite" className='fire-dgi'>DGignite</a>

                                    </li>
                                    <li><a href="/contact">Contact</a></li>
                                </ul>
                            </nav>
                        </div>
                        {/* <div className="offcanvas__btn">
                            <a href="#" className="tp-btn">Getting Started</a>
                        </div>
                        <div className="side-info-contact">
                            <span>we are here</span>
                            <p>1489 Langley Ave <br /> Grand Forks Afb, North.</p>
                        </div> */}
                        <div className="side-info-social">
                            <a href="https://www.linkedin.com/company/67087770/" target="_blank"><i><FaLinkedinIn className="fa-brands fa-linkedin-in" /></i></a>
                            {/* <a href="https://www.facebook.com/dgiworx" target="_blank"><i><FaFacebookF className="fa-brands fa-facebook-f" /></i></a> */}
                            <a href="https://www.instagram.com/dgiworx/" target="_blank"><i> <AiFillInstagram className="fa-brands fa-instagram" /></i></a>
                            {/* <a href="https://www.behance.net/dgiworx" target="_blank"><i ><BsBehance className="fa-brands fa-twitter" /></i></a> */}
                            <a href="https://www.youtube.com/channel/UCxN2y-6L8tL_ZNXYPq8_n3g" target="_blank"><i><TfiYoutube className="fa-brands fa-youtube-f" /></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`body-overlay ${isMobileMenuOpen ? 'opened' : ''}`}></div>
        </>
    )
}

export default HeaderUS;