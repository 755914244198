import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import testimonial from './testimonial.json';

function Testimonial() {
    
    const settings = {
        className: "dgi-testimonial",
        dots: true,
        arrows: true,
        infinite: true,
        centerPadding: "60px",
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 2, // Number of slides to show at a time
        slidesToScroll: 2,
        responsive: [

            {
                breakpoint:900, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div> <Slider {...settings}>
            {testimonial.map((testimonial, index) => (
                <div className="dgi-testimonial" key={index}>
                    <div className="tp-testimonial-5-item">
                        <div className="dgi-client-logo">
                            <img src={testimonial.logo} alt="" loading="lazy" />
                        </div>
                        <div className="dgi-client-content">
                            <p>{testimonial.description}</p>
                        </div>

                        <div className="tp-testimonial-5-avatar mt-3">
                            <div className="dgi-avatar-content">
                                <h5 className="tp-testimonial-5-avatar-title">{testimonial.name}</h5>
                                <span>{testimonial.designation}</span>
                                <div className="tp-testimonial-5-avatar-quote">
                                    <img src="assets/img/testimonial/testimonial-5-quote.png" alt="" loading="lazy" />
                                </div>
                            </div>
                        </div>
                        <div className="tp-testimonial-5-shape-1">
                            <img src="assets/img/testimonial/testimonial-5-shape-1.png" alt="" loading="lazy" />
                        </div>
                        <div className="dgi-shape-2">

                        </div>
                    </div>
                </div>

            ))}
        </Slider>

        </div>
    )
}

export default Testimonial;